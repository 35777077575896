import React from 'react'

interface Props {
  [key: string]: any
}

const Logo: React.FC<Props> = props => {
  return (
    <div {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          fill="#af8066"
          d="M140.977 164.689l-88.011 38.312L0 247.139l52.966 23.04v153.512L256 511.967l203.034-88.276-.264-.088.264-.707V269.385L512 246.345l-52.966-44.138v.794l-86.775-37.694"
        />
        <path
          d="M459.034 203.001l-202.417 88.011-.617.265-203.034-88.276 88.011-38.312c26.218 34.692 74.681 68.061 115.906 91.277 41.048-23.04 89.159-56.232 115.377-90.659l86.774 37.694z"
          fill="#7f8c8d"
        />
        <path
          fill="#af8066"
          d="M459.034 422.896l-.264.707.264.088L256 511.967v-220.69l.618-.265 52.348 43.608 150.068-65.235z"
        />
        <path fill="#7f5b53" d="M256 291.277v220.69L52.966 423.691V270.179l150.068 65.236z" />
        <path
          fill="#cbb292"
          d="M256 291.277l-52.966 44.138-150.068-65.236L0 247.139l52.966-44.138z"
        />
        <path
          fill="#fdd7ad"
          d="M512 246.345l-52.966 23.04-150.068 65.235-52.348-43.608 202.416-88.011v-.794z"
        />
        <path
          d="M382.499 149.947c-2.99 5.39-6.414 10.527-10.24 15.36-26.218 34.428-74.328 67.619-115.377 90.659-41.225-23.217-89.688-56.585-115.906-91.277-3.592-4.671-6.837-9.598-9.71-14.742-31.338-53.054-31.338-106.019 0-132.59 19.619-14.937 44.77-20.591 68.895-15.488s44.831 20.457 56.722 42.059c11.893-21.6 32.599-36.952 56.723-42.054 24.123-5.103 49.273.55 68.894 15.483 31.426 26.571 31.426 79.537-.001 132.59z"
          fill="#ff5364"
        />
        <g fill="#fb7b76">
          <path d="M176.552 61.76c-2.342.001-4.588-.928-6.244-2.584-1.656-1.656-2.585-3.902-2.584-6.244v-8.828c0-4.875 3.952-8.828 8.828-8.828s8.828 3.952 8.828 8.828v8.828c.001 2.342-.928 4.588-2.584 6.244-1.657 1.655-3.903 2.585-6.244 2.584zM176.552 105.898c-2.342.001-4.588-.928-6.244-2.584-1.656-1.656-2.585-3.902-2.584-6.244v-8.828c0-4.875 3.952-8.828 8.828-8.828s8.828 3.952 8.828 8.828v8.828c.001 2.342-.928 4.588-2.584 6.244-1.657 1.655-3.903 2.585-6.244 2.584zM158.897 79.415h-8.828c-4.875 0-8.828-3.952-8.828-8.828s3.952-8.828 8.828-8.828h8.828c4.875 0 8.828 3.952 8.828 8.828s-3.953 8.828-8.828 8.828zM203.034 79.415h-8.828c-4.875 0-8.828-3.952-8.828-8.828s3.952-8.828 8.828-8.828h8.828c4.875 0 8.828 3.952 8.828 8.828s-3.952 8.828-8.828 8.828z" />
        </g>
      </svg>
    </div>
  )
}

export default Logo
